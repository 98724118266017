<template>
	<div>
		<div class="">
			<div class="card" v-if="companyId">
				<v-server-table
					ref="creditSystemUsageTable"
					:columns="columns"
					:options="options"
					@loading="tableLoading = true"
					@loaded="tableLoading = false"
					@row-click="onRowClicked"
					>
					<span slot="id" slot-scope="props">
						<span>{{ props.index }}</span>
					</span>

					<div slot="staff" slot-scope="props">
						<div>
							<span>{{ props.row.user.fname }} {{ props.row.user.lname }}</span>
						</div>
						<div>
							<span class="text-muted">{{ props.row.user.email }}</span>
						</div>
					</div>
					<span slot="main_wallet" slot-scope="props">
						<span>{{ props.row.wallet.amount || 0 }}</span>
					</span>
					<span slot="credit_wallet" slot-scope="props">
						<span>{{ props.row.wallet.credit_amount || 0 }}</span>
					</span>

					<div class="d-flex flex-row py-3 px-3" slot="beforeTable">
						<date-range-picker
							ref="dateRangePicker"
							:locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
							:maxDate="new Date()"
							:singleDatePicker="false"
							:timePicker="true"
							:showDropdowns="true"
							:autoApply="true"
							@update="filterByDateRange"
							v-model="dateRange"
							>
							<template v-slot:input="picker" style="min-width: 350px">
								{{ picker.startDate | date }} - {{ picker.endDate | date }}
							</template>
						</date-range-picker>
						<button class="btn bg-light btn-sm mx-2" @click.prevent="exportReport">
							Export
						</button>
					</div>
				</v-server-table>
			</div>
		</div>
	</div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')

export default {
  components: { DateRangePicker },
  filters: {
    date (value) {
      if (!value) return ''
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return Intl.DateTimeFormat('en-US', options).format(value)
    }
  },
  props: {
    userId: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    const vm = this
    return {
      companyId: null,
      staffName: null,
      dateRange: {
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate()
      },
      tableLoading: false,
      companies: null,
      columns: [
        'id',
        'staff',
        'wallet_history.title',
        'amount',
        'balance_before',
        'balance_after',
        'created_at'
      ],
      options: {
        filterable: false,
        dateColumns: ['created_at'],
        headings: {
          id: 'ID',
          staff: 'Staff',
          'wallet_history.title': 'Wallet History',
          amount: 'Amount',
          balance_before: 'Balance Before',
          balance_after: 'Balance After',
          created_at: 'Created At'
        },
        requestFunction: (data) => {
          return new Promise((resolve) => {
            if (!data.staffId) {
              data.staffId = vm.userId
            }

            if (!data.startDate && vm.dateRange.startDate) {
              data.startDate = vm.dateRange.startDate
            }

            if (!data.endDate && vm.dateRange.endDate) {
              data.endDate = vm.dateRange.endDate
            }

            vm.fetchStaff(data)
              .then((d) => resolve(d))
              .catch(function () {
                // this.dispatch('error', e);
                resolve({ data: { data: [], count: 0 } })
              })
          })
        }
      }
    }
  },
  mounted () {
    this.fetchUser()
  },
  methods: {
    async fetchUser () {
      const result = await this.axios.get(`/v1/users/${this.userId}`)
      this.currentStaff = result.data
      this.companyId = result.data.corporate_id
      this.staffName = `${result.data.fname} ${result.data.lname}`
    },
    exportReport () {
      const vm = this
      let q = `staffId=${vm.userId}`
      if (vm.dateRange.startDate) {
        q = `&startDate=${vm.dateRange.startDate}`
      }

      if (vm.dateRange.endDate) {
        q = `${q}&endDate=${vm.dateRange.endDate}`
      }
      // credit-line-usages/expor
      const url = `${process.env.VUE_APP_API_URL}/v1/corporates/${this.companyId}/credit-line-usages/export?${q}`
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      const body = document.getElementsByTagName('body')
      body[0].appendChild(a)
      a.click()
    },
    filterByDateRange ({ startDate, endDate }) {
      this.$refs.creditSystemUsageTable.setCustomFilters({ startDate, endDate })
    },
    onRowClicked () {},
    async fetchStaff (params) {
      const result = await this.axios.get(
        `/v1/corporates/${this.companyId}/credit-line-usages`,
        { params }
      )
      const newResult = {
        ...result.data,
        data: result.data?.data,
        count: result.data?.total
      }
      return {
        data: newResult
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}

.nav-link.active {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
